import { Card, CardColumns } from 'react-bootstrap';
import HadithContentAccordion from './HadithContentAccordion';

const HadithRenderer = ({ jsonPOST, matchArray = [] }) => {
  return (
    <CardColumns>
      <Card
        bg="light"
        // bg='info'
        // text='white'
        className="my-3"
      >
        <Card.Body>
          <Card.Text className="mt-3 text-center hadith-heading">
            {jsonPOST.book}
          </Card.Text>
          <span className="py-4 px-2 hadith-heading">
            <div dangerouslySetInnerHTML={{ __html: jsonPOST.title }}></div>
          </span>
          {jsonPOST.hukm && (
            <span className="py-4 px-2 hadith-heading">
              {/* <div>حکم:</div> */}
              <div dangerouslySetInnerHTML={{ __html: jsonPOST.hukm }}></div>
            </span>
          )}
          <h1 className="hadith-heading" style={{ marginTop: '-40px' }}>
            تفصیل
          </h1>

          <span>
            <div className="mt-3">
              {jsonPOST.html_content && matchArray && (
                <HadithContentAccordion
                  matchArray={matchArray}
                  hadith_array={JSON.parse(jsonPOST.html_content)}
                />
              )}
            </div>
          </span>
        </Card.Body>

        {jsonPOST.references && (
          <Card.Footer as="h3" className="">
            <span className="mb-8 mt-2">حوالہ جات</span>
            <div
              className="hadith-references"
              dangerouslySetInnerHTML={{ __html: jsonPOST.references }}
            ></div>
          </Card.Footer>
        )}
      </Card>
    </CardColumns>
  );
};

export default HadithRenderer;
