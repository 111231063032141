import React from 'react';
import { Helmet } from 'react-helmet-async';
import logo from '../../images/logo/tehqeqatehadees-logo.svg';
import { baseUrl } from '../../utils/constans';
export default function ReactHelmet({
  title,
  description,
  author,
  keyWords,
  canonical,
}) {
  return (
    <Helmet>
      <link rel="canonical" href={canonical && canonical} />
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="author" content={author} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keyWords.join(', ')} />
      {/* End standard metadata tags */}
      {/* Open Graph Tags start  */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={logo} />
      <meta property="og:url" content={baseUrl} />
      {/* Open Graph Tags end  */}
    </Helmet>
  );
}
