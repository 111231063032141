// const endpoint = `${base_url}/api/v1/hadith/list?book=${book}&page=${pageNo}`

import React from 'react';
import { useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useEffect } from 'react';
import { Pagination } from '@material-ui/lab';
import ReactHelmet from '../seo/ReactHelmet/ReactHelmet';

const ListAllHadith = () => {
  const is_array = (obj) =>
    Object.prototype.toString.call(obj) === '[object Array]';
  const [listingStarted, setListingStarted] = useState(false);
  const [result, setResult] = useState();
  const [message, setMessage] = useState('');
  const [page, setPage] = React.useState(1);
  const [countPages, setCount] = useState(1);
  const pageSize = 10;

  const handleChange = (e, value) => {
    setPage(value);
    list_hadith(e.target.innerText);
  };

  const list_hadith = async (pageNo) => {
    const base_url = process.env.REACT_APP_BASE_URL;
    const endpoint = `${base_url}/api/v1/hadith/list?page=${pageNo}`;
    setListingStarted(true);
    let response = await fetch(endpoint);

    if (response.ok) {
      let res_result = await response.json();
      let countPg = Math.ceil(res_result['total_records'] / pageSize);
      setCount(countPg);
      if (is_array(res_result['data'])) {
        setResult(res_result['data']);
        setListingStarted(false);
        setMessage(
          ` ${res_result['total_records']} میں سے  ${res_result['data'].length} روایات `,
        );
      }
    } else {
      alert('HTTP-Error: ' + response.status);
    }
  };

  useEffect(() => {
    list_hadith(1);
    // alert(JSON.stringify(response))
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div>
      <ReactHelmet
        canonical={window.location.href}
        author="تحقیقات حدیث"
        title={` ${'تحقیقات حدیث'} | ${result && result[0].book} `}
        description="ویبسائٹ پر پوجود تمام احادیث کا مجموعہ"
        keyWords={[
          ['غیر معتبر'],
          ['موضوع'],
          ['من گھڑت'],
          ['روایات'],
          ['فنی'],
          ['جائزہ'],
          ['مجموعہ'],
        ]}
      />
      <h1 className="mt-5 mb-3" style={{ color: '#F58221', fontSize: '28px' }}>
        {message}
      </h1>
      {listingStarted ? (
        <Spinner
          className="m-5"
          animation="border"
          variant="warning"
          role="status"
        >
          <span className="visually-hidden">تلاش جاری ہے۔۔۔</span>
        </Spinner>
      ) : (
        result && (
          <Card bg="light" className="my-3">
            <Card.Body>
              {result.map((hadith, idx) => (
                <div
                  onClick={() => openInNewTab(`/hadiths?id=${hadith.id}`)}
                  className="border-bottom border-secondary py-2 px-2 hadith-search-div"
                  key={idx}
                >
                  <Card.Text className="mt-2 hadith-heading search-hadith-heading">
                    {hadith.book}{' '}
                    <span
                      dangerouslySetInnerHTML={{ __html: hadith.title }}
                    ></span>
                  </Card.Text>
                </div>
              ))}
            </Card.Body>
            <Pagination
              className="pagination-cls"
              count={countPages}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              page={page}
            />
          </Card>
        )
      )}
    </div>
  );
};

export default ListAllHadith;
