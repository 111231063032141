const sync_hadith_json_parser_function = (html_string) => {

    // console.log('html before parsing ------')
    // console.log(html_string)

    let headings_array = []
    let content_array = []

    let references_str = ''

    const parser = new DOMParser();
    
    const docx = parser.parseFromString(html_string, "text/html");

    // CLEAN PARAGRAPH ELEMENTS
    const p_elems = docx.body.querySelectorAll('p')

    // //khrab first reference for testing
    // for (let p_elem of p_elems) {
    //     if (p_elem.className.includes("MsoFootnoteText")) {
    //         p_elem.className = ''
    //         break
    //     }
    // }
    // // --

    let content_str = ''
    let reference_added = false

    for (let p_elem of p_elems){

        // ==== add more conditions to detect ====
        if(reference_added) {
            references_str += p_elem.outerHTML
            continue
        }
        // TODO - references
        if (p_elem.className.includes("MsoFootnoteText")) {
            references_str += p_elem.outerHTML
            reference_added = true
            continue
        }
        // Another condition to detect references: href have ftnref instead of ftn
        if (p_elem.querySelector('a')?.href?.includes('ftnref')) {
            references_str += p_elem.outerHTML
            reference_added = true
            continue
        }

        // if element has heading class ("RighHadding")
        if (p_elem.className.includes("RighHadding")) {

            content_array.push(content_str)
            content_str = ''
            headings_array.push(p_elem.outerHTML)
            continue
        }

        // new child cond
        if (p_elem.firstElementChild) {

            // let push = undefined
            let push = false
            let current_elem = p_elem.firstElementChild

            // if non-empty bold child
            if ((current_elem.nodeName === "B" || current_elem.nodeName === "STRONG") && (current_elem.textContent.trim()!=='')) {
                // push = current_elem
                push = true
            }

            // if non-empty bold grand-child
            // grand child exists and child is a span
            else if(current_elem.firstElementChild && current_elem.nodeName === 'SPAN'){
                current_elem = current_elem.firstElementChild
                if((current_elem.nodeName === "B" || current_elem.nodeName === "STRONG") && current_elem.textContent.trim()!==''){
                    // push = current_elem
                    push = true
                }
            }

            if (push) {

                content_array.push(content_str)
                content_str = ''

                // headings_array.push(push.outerHTML)
                // p_elem.removeChild(push)

                // content_str += p_elem.outerHTML
                headings_array.push(p_elem.outerHTML)
                continue

            }

        }

        content_str += p_elem.outerHTML
        
    }

    if (content_str !== '') {
        content_array.push(content_str)
    }
    if (content_array[0] === '') {
        content_array.shift()
    }
    else {
        headings_array.unshift('')
    }

    // Array of JSONs
    let heading_content_array = []

    for (let i = 0; i < headings_array.length; i++){

        let head = headings_array[i]
        let content = content_array[i]
        
        let json_piece = {
            "heading": head,
            "content": content
        }
        
        heading_content_array.push(json_piece) 
    }

    // console.log('heading and content array')
    // console.log(heading_content_array)
    // console.log('references')
    // console.log(references_str)

    return [heading_content_array, references_str]     
}

export default sync_hadith_json_parser_function