import React from 'react';
import { Helmet } from 'react-helmet-async';
import { baseUrl, sitemapPaths } from '../../utils/constans';

const Sitemap = () => {
  const sitemapXml = `<?xml version="1.0" encoding="UTF-8"?>
   <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
   <url>
     <loc>${baseUrl}</loc>
     <priority>1.0</priority>
   </url>

  ${sitemapPaths
    .map((path) => {
      const url = `${baseUrl}${path}`;
      return `
            <url>
              <loc>${url}</loc>
              <priority>0.8</priority>
            </url>
          `;
    })
    .join('')}
</urlset>`;

  return (
    <Helmet>
      <link rel="sitemap" type="application/xml" href={`data:${sitemapXml}`} />
    </Helmet>
  );
};

export default Sitemap;
