import React from 'react';

import { useRef } from 'react';

import { Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';

import { Card } from 'react-bootstrap';

import { useEffect } from 'react';

import { Pagination } from '@material-ui/lab';
import { useParams } from '../customHook/useparams';
import ReactHelmet from '../seo/ReactHelmet/ReactHelmet';

const SearchPage = () => {
  const searchRef = useRef();
  const is_array = (obj) =>
    Object.prototype.toString.call(obj) === '[object Array]';
  const [result, setResult] = useState();
  const [searchStarted, setSearchStarted] = useState(false);
  const [message, setMessage] = useState('');
  const [page, setPage] = React.useState(1);
  const [countPages, setCount] = useState(0);
  const [params, setParams] = useParams();
  const [searchBy, setSearchBy] = useState('');
  const pageSize = 10;

  const handleChange = (event, value) => {
    setPage(value);
    search_hadith(searchRef.current.value, value);
  };

  const search_hadith = async (search_keyword, pageNo) => {
    const base_url = process.env.REACT_APP_BASE_URL;
    const endpoint = `${base_url}/api/v1/hadith/search?search_keyword=${search_keyword}&page=${pageNo}`;
    let response = await fetch(endpoint);
    setParams('searchBy', search_keyword);
    setParams('page', pageNo);
    if (response.ok) {
      setSearchStarted(true);
      let res_result = await response.json();
      let countPg = Math.ceil(res_result['total_records'] / pageSize);
      setCount(countPg);
      if (is_array(res_result['data'])) {
        setResult(res_result['data']);
        setSearchStarted(false);
        setMessage(
          ` ${res_result['total_records']} میں سے  ${res_result['data'].length} نتائج`,
        );
      }
    } else {
      alert('HTTP-Error: ' + response.status);
    }
  };

  const on_click_search = () => {
    if (searchRef.current.value === '') {
      setMessage('روایت کے الفاظ لکھیں');
      return;
    }
    setSearchBy(searchRef.current.value);

    search_hadith(searchRef.current.value, 1);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        // callMyFunction();
        on_click_search();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const searchKeyPress = (e) => {
    if (e.key === 'Enter') {
      on_click_search();
    }
  };

  // onClick={() =>  history.push(`/hadiths?id=${hadith.id}`)}

  return (
    <div style={{ fontSize: 'x-large' }}>
      <ReactHelmet
        canonical={window.location.href}
        author="تحقیقات حدیث"
        title={` ${'تحقیقات حدیث'} | ${'غیر معتبر روایات کا فنی جائزہ'} | ${'روایت تلاش کریں'} `}
        description={`${'روایت تلاش کریں'}  `}
        keyWords={[
          [searchBy && searchBy],
          ['غیر معتبر'],
          ['موضوع'],
          ['من گھڑت'],
          ['روایات'],
          ['فنی'],
          ['جائزہ'],
          ['مجموعہ'],
          ['روایت تلاش'],
        ]}
      />
      <h1 className="my-5">روایت تلاش کریں</h1>
      <Form.Group className="my-4 search-field" controlId="id_search_input">
        <Form.Label className="mx-2 note-text">
          نوٹ: تلاش کے لیے لکھے جانے والے کلمات میں سے کوئی کلمہ اگرچہ روایت کے
          عربی اور اردو متن میں نہ ہو، روایت کی تفصیلی تحقیق میں موجود ہو، پھر
          بھی وہ کلمہ مطلوبہ روایت تک رسائی میں مدد گار ہوگا۔{' '}
        </Form.Label>
        <div className="btn-group">
          <Form.Control
            style={{ width: '100%' }}
            ref={searchRef}
            className="pt-4"
            placeholder="روایت کے الفاظ۔۔۔"
            onKeyPress={searchKeyPress}
          />
          <Button
            style={{ backgroundColor: '#005380', color: 'white' }}
            className="btn pt-3 search-btn"
            onClick={on_click_search}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </div>
      </Form.Group>
      <div>
        <h3 style={{ color: '#F58221' }}>{message}</h3>

        {result ? (
          <Card bg="light" className="my-3">
            <Card.Body>
              {result.map((hadith, idx) => (
                <div
                  onClick={() =>
                    openInNewTab(
                      `/hadiths?id=${
                        hadith.id
                      }&searchBy=${searchRef.current.value.trim()}`,
                    )
                  }
                  className="border-bottom border-secondary py-2 px-2 hadith-search-div"
                  key={idx}
                >
                  <Card.Text className="mt-2 hadith-heading search-hadith-heading">
                    {hadith.book}{' '}
                    <span
                      dangerouslySetInnerHTML={{ __html: hadith.title }}
                    ></span>
                  </Card.Text>
                  {/* <Card.Text className="py-1 px-2 hadith-heading search-hadith-content">{hadith.title}</Card.Text> */}
                  {/* <Card.Text className="py-1 px-2 hadith-heading search-hadith-content"><div dangerouslySetInnerHTML={{ __html: hadith.title }}></div></Card.Text> */}
                </div>
              ))}
            </Card.Body>
            <Pagination
              className="pagination-cls"
              count={countPages}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
            />
          </Card>
        ) : (
          searchStarted && (
            <Spinner animation="border" variant="warning" role="status">
              <span className="visually-hidden">تلاش جاری ہے۔۔۔</span>
            </Spinner>
          )
        )}
      </div>
    </div>
  );
};

export default SearchPage;
