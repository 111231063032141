// import { Button } from 'bootstrap'
import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
// import Marquee from 'react-text-marquee'

import juz from '../images/al-juz-img.png';
import ReactHelmet from '../seo/ReactHelmet/ReactHelmet';

const PDFComponent = () => {
  const pdfBooks = [
    {
      title: 'الجزء فی فضائل القرآن',
      link: '/books/Al-Juz-fi-fazail-e-quran.pdf',
      img: require('./../images/al-juz-img.png').default,
    },
    {
      title: 'بہترین امت اور اس کی ذمہ داری',
      link: '/books/Behtreen-ummat-or-us-ki-zimedari.pdf',
      img: require('./../images/Behtreen.png').default,
    },
    {
      title: 'حضرت مولانا جون پوری صاحب رحمہ اللہ کا من گھڑت روایات پر تعاقب',
      link: '/books/Joon-poori-sahab-ka-taaqub.pdf',
      img: require('./../images/joonpoori.png').default,
    },
    {
      title:
        'حضرت مولانا رشید احمد گنگوہی رحمہ اللہ و دیگر اکابرین عظام کا من گھڑت روایات پر تعاقب ',
      link: '/books/Gngohi-sahab-ka-taqub.pdf',
      img: require('./../images/Gangohi.png').default,
    },
    {
      title:
        'حضرت حکیم الامت مولانا اشرف علی تھانوی رحمہ اللہ کا من گھڑت روایات پر تعاقب',
      link: '/books/Hakeem-ul-ummat-ka-taqub.pdf',
      img: require('./../images/Hakeem.png').default,
    },
    {
      title: 'مختصر کتاب غیر معتبر روایات کا فنی جائزہ',
      link: '/books/Mukhtasar.pdf',
      img: require('./../images/Mukhtasar.png').default,
    },
    {
      title: 'حریص عليكم: یعنی نبی اکرم صلی اللہ علیہ وسلم کا غم امت',
      link: '/books/Harees-un-alikum.pdf',
      img: require('./../images/gham-e-ummat.png').default,
    },
    {
      title:
        '(حصہ اول) پاک و ہند میں زبان زد عوام وخواص غیر معتبر روایات کا فنی جائزہ',
      link: '/books/Vol-1.pdf',
      img: require('./../images/Vol-1.png').default,
    },
    {
      title:
        '(حصہ دوم) پاک و ہند میں زبان زد عوام وخواص غیر معتبر روایات کا فنی جائزہ',
      link: '/books/Vol-2.pdf',
      img: require('./../images/Vol-2.png').default,
    },
    {
      title:
        '(حصہ سوم) پاک و ہند میں زبان زد عوام وخواص غیر معتبر روایات کا فنی جائزہ',
      link: '/books/Vol-3.pdf',
      img: require('./../images/Vol-3.png').default,
    },
    {
      title:
        '(حصہ چہارم) پاک و ہند میں زبان زد عوام وخواص غیر معتبر روایات کا فنی جائزہ',
      link: '/books/Vol-4.pdf',
      img: require('./../images/Vol-4.png').default,
    },
    {
      title:
        '(حصہ پنجم) پاک و ہند میں زبان زد عوام وخواص غیر معتبر روایات کا فنی جائزہ',
      link: '/books/Vol-5.pdf',
      img: require('./../images/Vol-5.png').default,
    },
  ];

  return (
    <Container className="mt-4">
      <ReactHelmet
        canonical={window.location.href}
        author="تحقیقات حدیث"
        title="تحقیقات حدیث | غیر معتبر روایات کا فنی جائزہ | PDF کتابیں"
        description="مستند حدیثوں کی کتابیں پی ڈی ایف فارمیٹ میں ڈاؤنلوڈ کریں"
        keyWords={[
          ['حدیث کی کتاب ڈاؤنلوڈ'],
          ['حدیث کی کتاب ڈاؤنلوڈ کریں'],
          ['کتابوں کی ڈاؤنلوڈ'],
          ['حدیث کی کتابیں'],
          ['مستند حدیث'],
          ['حدیث پی ڈی ایف'],
        ]}
      />
      <Row>
        {pdfBooks.map((book, index) => {
          return (
            <React.Fragment key={index}>
              <Col xs={6} md={4} className="mb-4">
                <Card
                  className="text-center"
                  style={{ width: '100%', borderWidth: 0, padding: '10px' }}
                >
                  <Card.Img
                    variant="top"
                    src={book.img}
                    title="  مختلف مضامین پر مشتمل احادیث کی پی ڈی ایف کتابیں "
                    loading="lazy"
                    alt={book.title}
                    width={'100%'}
                    height={'100%'}
                  />
                  <Card.Text
                    style={{
                      height: '40px',
                      marginTop: '10px',
                      fontFamily: 'jamil',
                    }}
                  >
                    {book.title}
                  </Card.Text>
                  <Card.Body>
                    <Button
                      variant="success"
                      className="book-button pb-0"
                      href={book.link}
                    >
                      Download
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </Container>
  );
};

export default PDFComponent;
