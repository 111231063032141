 import * as React from 'react';
 import { Count, HtmlEditor, Image, Link, RichTextEditor, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
 RichTextEditor.Inject(HtmlEditor, Image, Link, Toolbar, Count);
 
 class SyncRTEClass extends React.Component {
     constructor() {
         super(...arguments);
         this.template = ``;

     }
     componentDidMount() {
         this.rteObj = new RichTextEditor({
             height: 400,
             valueTemplate: this.template
         }, '#rteElement');

     }


     render() {
         return (
         <div>
            <textarea id="rteElement"/>

            <div className="d-flex justify-content-end">
                <button className="btn btn-success w-25 mt-2" onClick={() => {

                const html_content = this.rteObj.getHtml()

                // todo new
                const raw_content = this.rteObj.getText()

                // replace quote chars
                const html_string = html_content.replaceAll('&quot;', "'").replaceAll('<o:p>', '').replaceAll('</o:p>', '')
                const cleaned_html = this.props.cleanerFunction(html_string)
                const [parsed_array, refStr] = this.props.jsonParser(cleaned_html)

                const cleanedRefStr = this.props.referencesCleanerFunction(refStr)
                // const cleanedRefStr = refStr

                this.props.previewFunction(parsed_array, cleanedRefStr, raw_content)

                }}>Show rendered hadith</button>
            </div>

         </div>
         );
     }
 }
 export default SyncRTEClass;
 
 