 import * as React from 'react';
 import { Count, HtmlEditor, Image, Link, RichTextEditor, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
 RichTextEditor.Inject(HtmlEditor, Image, Link, Toolbar, Count);
 
 class SyncRTEMiniClass2 extends React.Component {
     constructor() {
         super(...arguments);
         this.template = ``;

     }
     componentDidMount() {
         this.rteObj = new RichTextEditor({
             height: 150,
             valueTemplate: this.template
         }, '#rteElementMini2');
        //  this.rteObj.toolbarSettings.enable = false

     }


     render() {
         return (
         <div>
            <textarea id="rteElementMini2" ref={this.props.passed_ref}/>
         </div>
         );
     }
 }
 export default SyncRTEMiniClass2;
 
 