import firebase from "firebase/app";
import "firebase/auth";

// TODO - firebase db for book list
import "firebase/database"

// const firebaseConfig = {
//     apiKey: "AIzaSyBhl2fVF3LGhoWVGlNwRD1FEG1DV1dxcVI",
//     authDomain: "auth-development-de3be.firebaseapp.com",
//     projectId: "auth-development-de3be",
//     storageBucket: "auth-development-de3be.appspot.com",
//     messagingSenderId: "756614875481",
//     appId: "1:756614875481:web:86cbb9b255f9ee4203a448"
//   };

// const app = firebase.initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// })

// TODO Add db

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
})

// const app = firebase.initializeApp(firebaseConfig)

export const database = firebase.database();

export const auth = app.auth()
export default app