import React from 'react'
import { Container } from 'react-bootstrap'

const MediumContainer = (props) => {
    const Inner = props.inner
    const Above = props.above
    const active_key = props.active_key ? props.active_key : "index"

    // const Above = NavbarComponent
    // const Above = undefined

    return (
        <>
            {Above ? <Above active_key={active_key} /> : null}
            <Container 
                className="d-flex justify-content-center"
                style={{ minHeight: "100vh" }}
                >
                    
                <div className="w-100" style={{ maxWidth: "800px" }}>
                    <Inner />
                </div>
            </Container>
        </>
    )
}

export default MediumContainer
