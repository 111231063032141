import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from './../images/logo/tehqeqatehadees-logo.svg';
export default function PublicNavbarComponent(props) {
  // alert (props.active_key)

  let active_nav_item = props.active_key ? props.active_key : 'index';

  const ContainerBody = () => (
    <Container>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav activeKey={active_nav_item}>
          <Nav.Link as={Link} eventkey="index" to="/">
            سرورق
          </Nav.Link>
          <Nav.Link as={Link} eventkey="search" to="/search">
            روایت تلاش کریں
          </Nav.Link>
          <Nav.Link as={Link} eventkey="pdf" to="/books-pdf">
            PDF کتابیں
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Brand as={Link} eventkey="index" to="/">
        <span className="logo-main">
          <img
            src={logo}
            alt="Logo"
            title="غیر معتبر روایات کا فنی جائزہ"
            loading="lazy"
            width="100%"
            height="100%"
          />
        </span>
      </Navbar.Brand>
    </Container>
  );

  return (
    <>
      {active_nav_item === 'index' ? (
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={{
            backgroundColor: `rgba(0, 56, 102, 0.5)`,
            fontSize: 'large',
          }}
        >
          <ContainerBody />
        </Navbar>
      ) : (
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          style={{ backgroundColor: `#003866`, fontSize: 'large' }}
        >
          <ContainerBody />
        </Navbar>
      )}
    </>
  );
}
