const sync_references_lang_cleaner_function = (html_string) => {

    if (html_string === '') {
        return ''
    }

    const parser = new DOMParser();
    
    const docp = parser.parseFromString(html_string, "text/html");

    const span_elems = docp.body.querySelectorAll('span')

    for (let spam_elem of span_elems) { 
        let language_attrib = spam_elem.lang
        
        if (language_attrib !== 'EN-US' && language_attrib !== '') {
            spam_elem.removeAttribute('lang')
        }
    }

    // ==== TODO FIX REFERENCE LINK TAGS ==== \\
    const p_elems = docp.body.querySelectorAll('p')

    for (let p_elem of p_elems) {
        // find <a> tag and search if it contains MsoFootnoteReference class element
        const a_elem = p_elem.querySelector('a')
        if (a_elem && !a_elem.querySelector('.MsoFootnoteReference')) {
            // ^ if no class is found
            const elem_to_add = p_elem.querySelector('.MsoFootnoteReference')?.outerHTML
            // remove old elem
            
            // add new elem
            if (elem_to_add) {
                p_elem.querySelector('.MsoFootnoteReference').remove()
                a_elem.innerHTML = elem_to_add
            }        
        }
    }
    return docp.body.innerHTML
}

export default sync_references_lang_cleaner_function