import '../App.css';

import { AuthProvider } from '../contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
// import RTEEditor from "./RTEEditor";

import SmallContainer from './styling_wrappers/SmallContainer';
import MediumContainer from './styling_wrappers/MediumContainer';
import PrivateStyledRoute from './styling_wrappers/PrivateStyledRoute';
import NavbarComponent from './NavbarComponent';
import AddBook from './AddBook';
import AddBookData from './AddBookData';
import Logout from './Logout';

import DefaultContainer from './styling_wrappers/DefaultContainer';
import SearchPage from './SearchPage';
import ListAllHadith from './ListAllHadith';

import NavBarWrapper from './styling_wrappers/NavBarWrapper';

import PDFComponent from './PDFComponent';
import HadithDisplayPage from './HadithDisplayPage';

import SyncRTE from './SyncRTE';
import LargeContainer from './styling_wrappers/LargeContainer';

import ReactGA from 'react-ga4';
import { useEffect } from 'react';

// React Helmet
import { HelmetProvider } from 'react-helmet-async';
import Sitemap from '../seo/SiteMap/SiteMap';

const reload = () => window.location.reload();

function App() {
  const ga_key = process.env.REACT_APP_MEASUREMENT_ID;

  useEffect(() => {
    ReactGA.initialize(ga_key);

    ReactGA.send('pageview');
    // ReactGA.pageview(window.location.pathname + window.location.search)
  }, []);
  return (
    <Router>
      <div className="my-font-class">
        <HelmetProvider>
          <AuthProvider>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <DefaultContainer {...props} inner={Dashboard} />
                )}
              />
              <PrivateStyledRoute
                path="/change-password"
                component={ChangePassword}
                style_component={SmallContainer}
                above={NavbarComponent}
                active_key="change"
                class_name="d-flex justify-content-center"
              />
              <Route
                path="/login"
                render={(props) => <SmallContainer {...props} inner={Login} />}
              />
              <Route
                path="/forgot-password"
                render={(props) => (
                  <SmallContainer {...props} inner={ForgotPassword} />
                )}
              />
              {/* <PrivateStyledRoute path="/rte" component={RTEEditor} style_component={MediumContainer} above={NavbarComponent} active_key="rte" /> */}
              <PrivateStyledRoute
                path="/add-book"
                component={AddBook}
                style_component={MediumContainer}
                above={NavbarComponent}
                active_key="add-book"
                class_name="d-flex justify-content-center"
              />
              <PrivateStyledRoute
                path="/add-book-data"
                component={AddBookData}
                style_component={SmallContainer}
                above={NavbarComponent}
                active_key="add-data"
                class_name="d-flex justify-content-center my-5"
              />
              <PrivateRoute path="/logout" component={Logout} />
              <Route
                path="/search"
                render={(props) => (
                  <MediumContainer
                    {...props}
                    inner={SearchPage}
                    above={NavBarWrapper}
                    active_key="search"
                  />
                )}
              />
              <Route
                path="/list-hadiths"
                render={(props) => (
                  <MediumContainer
                    {...props}
                    inner={ListAllHadith}
                    above={NavBarWrapper}
                    active_key="list-hadiths"
                  />
                )}
              />
              <Route
                path="/books-pdf"
                render={(props) => (
                  <MediumContainer
                    {...props}
                    inner={PDFComponent}
                    above={NavBarWrapper}
                    active_key="pdf"
                  />
                )}
              />
              <Route
                path="/hadiths"
                render={(props) => (
                  <MediumContainer
                    {...props}
                    inner={HadithDisplayPage}
                    above={NavBarWrapper}
                    active_key="search"
                  />
                )}
              />
              <Route
                path="/sync"
                render={(props) => (
                  <LargeContainer
                    {...props}
                    inner={SyncRTE}
                    above={NavBarWrapper}
                    active_key="add-data"
                  />
                )}
              />
              {/* Books */}
              <Route path="/books/book1.pdf" onEnter={reload} />
            </Switch>
            <Sitemap />
          </AuthProvider>
        </HelmetProvider>
      </div>
    </Router>
  );
}

export default App;
