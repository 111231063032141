import React from 'react'
import { useRef } from 'react'
import { Form } from 'react-bootstrap'

import { useState, useEffect } from 'react'

import { Button } from 'react-bootstrap'

import { useAuth } from '../contexts/AuthContext'
import { getBooks } from './Dashboard'


const AddBook = () => {

    const bookRef = useRef()

    // ==  firebase ==
    // get books list
    const { firebase_db } = useAuth()

    const [books, setBooks] = useState([])

    useEffect(() => {
        getBooks(firebase_db).then((value) => setBooks(value))
    }, [firebase_db])
    // ===

    const [message, setMessage] = useState('')
    
    const [messageColor, setMessageColor] = useState('green')

    const on_click_add_button = () => {
        const book_name = bookRef.current.value

        book_name && books.push(book_name) // this seems to rerender the page as well
        
        const books_str = books.join(',')

        // UPDATE FIREBASE DB
        firebase_db.ref('books/').set(books_str);
        
        setMessage(`کتاب [  ${book_name}  ] فہرست میں شامل کر دی گی ہے`)
        setMessageColor('green')
        document.querySelector('#id_book_name').value = ''
    }

    

    return (
        <>
        <div style={{ fontSize: '24px' }}>
            <Form.Group className="my-5" controlId="id_book_name">
                <Form.Label>برائے مہربانی املاء کا خیال رکھتے ہوئے جدید کتاب کے نام کا اندراج کیجئے</Form.Label>
                <Form.Control className="pt-3" as="textarea" ref={bookRef} placeholder="یہاں کتاب کا نام لکھیے" />
            </Form.Group>
            <div className="d-flex justify-content-end">
                <button className="btn btn-success pt-4 mt-2" onClick={on_click_add_button}>اس کتاب کو فہرست میں شامل کیجئے</button>
            </div>
            <div className="d-flex justify-content-center mt-4">
                <h2 style={{color: messageColor}}>{message}</h2>
            </div>
            <div>
                {books && books.length > 0 && <h3 className="my-4" style={{color: 'brown'}}>کتابوں کی فہرست</h3>}
                {books.map((bk,i)=>{
                    return <p key={i}>{`${i+1}۔ ${bk}`} <Button variant="danger" onClick={
                        () => {
                            // REMOVE ITEM
                            
                            const deleted_book = books.splice(i, 1)
                            setMessage(`کتاب [  ${deleted_book}  ] فھرست سے نکال دی گی ہے`)
                            setMessageColor('red')

                            // Convert to str and UPDATE FIREBASE DB
                            
                            const books_str = books.join(',')
                            firebase_db.ref('books/').set(books_str);

                            // setBooks(books) - this seems to be unnecessary
                        }
                    }>X</Button></p>
                })}
            </div>

        </div>
        </>
    )
}

export default AddBook

