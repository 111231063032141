import React from 'react'

const DefaultContainer = (props) => {
    const Inner = props.inner
    const Above = props.above

    return (
        <>
            {Above ? <Above /> : null}
            <Inner />
        </>
    )
}

export default DefaultContainer
