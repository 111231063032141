import React from 'react'
import { Link } from 'react-router-dom'
import logo from './../images/logo/tehqeqatehadees-logo.svg';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'

import { useAuth } from '../contexts/AuthContext'

export default function NavbarComponent(props) {

  let active_nav_item = props.active_key ? props.active_key : "index"

  const currentUser = useAuth()

  const ContainerBody = () => (
    <Container>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">

        <Nav activeKey={active_nav_item}>
          <NavDropdown className="userIcon"
            title={<svg xmlns="http://www.w3.org/2000/svg" 
            width="16" height="16" fill="currentColor" 
            className="bi bi-person-fill" viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>} id="collasible-nav-dropdown">
              <NavDropdown.Item>{currentUser.currentUser.email}</NavDropdown.Item>
              <NavDropdown.Item as={Link} eventkey="change" to="/change-password">Change password</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/logout">Log out</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} eventkey="index" to="/">سرورق</Nav.Link>
          <Nav.Link as={Link} eventkey="search" to="/search">روایت تلاش کریں</Nav.Link>
          <Nav.Link as={Link} eventkey="pdf" to="/books-pdf">PDF کتابیں</Nav.Link>

          <Nav.Link as={Link} eventkey="add-book" to="/add-book">فہرست میں جدید کتاب کا اضافہ</Nav.Link>
          <Nav.Link as={Link} eventkey="add-data" to="/add-book-data">خاص کتاب میں احادیث کا اندراج</Nav.Link>

          {/* <NavDropdown title={currentUser.currentUser.email} id="collasible-nav-dropdown"> */}
        </Nav>

      </Navbar.Collapse>
      <Navbar.Brand as={Link} eventkey="index" to="/">
        <span className="logo-main">
          <img src={logo} alt="Logo" />
        </span>
      </Navbar.Brand>
    </Container>)

  return (<>
    {active_nav_item === "index" ? (
      <Navbar collapseOnSelect expand="lg" variant="dark" style={{ backgroundColor: `rgba(0, 56, 102, 0.5)`, fontSize: 'large' }}>
        <ContainerBody />
      </Navbar>
    ) : (
      <Navbar collapseOnSelect expand="lg" variant="dark" style={{ backgroundColor: `#003866`, fontSize: 'large' }}>
        <ContainerBody />
      </Navbar>
    )}
  </>)
}

// #005380
// rgba(25, 25, 103, 1)
// rgba(51, 51, 204, 0.3) transparent