import React from 'react'
import { Container } from "react-bootstrap";

const SmallContainer = (props) => {
    const Inner = props.inner
    const Above = props.above
    const active_key = props.active_key ? props.active_key : "index"

    const class_name = props.class_name ? props.class_name : "d-flex align-items-center justify-content-center"

    return (
        <>
            {Above ? <Above active_key={active_key} /> : null}
            <Container 
                className={class_name}
                style={{ minHeight: "100vh" }}
                >
                    
                <div className="w-100" style={{ maxWidth: "400px" }}>
                    <Inner />
                </div>
            </Container>
        </>
    )
}

export default SmallContainer
