const sync_cleaner_function = (html_string, fix_lang_tags=true) => {
    
    // This function removes style attribute, converts fonts specified in style to classes and deletes lang attribute
    // console.log('Html before cleaning ----')
    // console.log(html_string)
    const parser = new DOMParser();
    
    const doc3 = parser.parseFromString(html_string, "text/html");

    // CLEAN PARAGRAPH ELEMENTS
    const p_elems = doc3.body.querySelectorAll('p')

    for (let p_elem of p_elems){
        
        let fontFam = p_elem.style.fontFamily

        if (fontFam !== ""){
            // remove quotes
            fontFam = fontFam.replaceAll('"', '').replaceAll("'", "").replaceAll('&quot;', '')
            // replace space with dash
            fontFam = fontFam.replaceAll(' ', '-')

            if (p_elem.className !== ''){
                p_elem.className += ` font-class-${fontFam}`
            }
            else{
                p_elem.className = `font-class-${fontFam}`
            }
            
        }

        p_elem.removeAttribute('style')
    }
    

    // CLEAN SPAN ELEMENTS
    const span_elems = doc3.body.querySelectorAll('span')

    
    for (let spam_elem of span_elems){

        if (fix_lang_tags){
            let language_attrib = spam_elem.lang
            // if (language_attrib !== 'EN-US' && language_attrib !== 'AR-SA' && language_attrib !== '') {
            //     spam_elem.lang = 'ur'
            // }
            // Removing lang attributes to fix disjointed words
            if (language_attrib !== 'EN-US' && language_attrib !== '') {
                spam_elem.removeAttribute('lang')
            }
        }
        
        let fontFam = spam_elem.style.fontFamily

        if (fontFam !== ""){
            // remove quotes
            fontFam = fontFam.replaceAll('"', '').replaceAll("'", "").replaceAll('&quot;', '')
            // replace space with dash
            fontFam = fontFam.replaceAll(' ', '-')

            if (spam_elem.className !== ''){
                spam_elem.className += ` font-class-${fontFam}`
            }
            else{
                spam_elem.className = `font-class-${fontFam}`
            }
            
        }

        spam_elem.removeAttribute('style')
    }

    return doc3.body.innerHTML
}

export default sync_cleaner_function