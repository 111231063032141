import NavbarComponent from '../NavbarComponent'
import PublicNavbarComponent from '../PublicNavbarComponent'
import { useAuth } from '../../contexts/AuthContext'

const NavBarWrapper = (props) => {

    const { currentUser } = useAuth()

    // alert (props.active_key)

    return (
        <>
            {currentUser ? <NavbarComponent {...props} /> : <PublicNavbarComponent {...props} />}
        </>
    )
}

export default NavBarWrapper
