import React from 'react';

import { Link } from 'react-router-dom';

// NAVBAR and BACKGROUND
import star_bg from '../images/background-img.jpeg';

// FONT-AWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import NavBarWrapper from './styling_wrappers/NavBarWrapper';
import ReactHelmet from '../seo/ReactHelmet/ReactHelmet';

export async function getBooks(firebase_db) {
  let books = [];
  // TODO - GETTING BOOK VALUES
  const dbRef = firebase_db.ref();

  let snapshot = undefined;

  try {
    snapshot = await dbRef.child('books').get();
  } catch (error) {}

  if (snapshot && snapshot.exists()) {
    // convert to list and return
    const books_str = snapshot.val();
    const books_list = books_str.split(',');

    books = books_list;
  } else {
    books = [];
  }

  return books;
}

export default function Dashboard() {
  return (
    <>
      <ReactHelmet
        canonical={window.location.href}
        author="تحقیقات حدیث"
        title="تحقیقات حدیث | سرورق | غیر معتبر روایات کا فنی جائزہ"
        description="موضوع (من گھڑت) لا أصل له ( بے اصل) واهي، اور مطروح احادیث کی تفصیلات کا سرچ انجن، اور اس کے علاوہ حدیث و علوم الحدیث کی دیگر تحقیقات"
        keyWords={[
          ['حدیث'],
          ['تحقیقات حدیث'],
          ['سرورق'],
          ['من گھڑت'],
          ['بے اصل'],
          ['احادیث'],
          ['حدیث و علوم الحدیث'],
        ]}
      />
      <div
        style={{ backgroundImage: `url(${star_bg})` }}
        className="dashboard-container"
      >
        <div className="bg-overlay"></div>
        <NavBarWrapper />
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: '500px' }}
        >
          <div>
            <div className="text-center mt-1">
              <h1
                style={{ color: 'white' }}
                className="text-center my-4 animated animatedFadeInUp fadeInUp"
              >
                سرورق
              </h1>
            </div>

            <div
              className="text-center mt-3 animated animatedFadeInUp fadeInUp"
              style={{ animationDelay: '0.4s' }}
            >
              <Link
                style={{ backgroundColor: '#ef7d08', color: 'white' }}
                to="/books-pdf"
                className="btn d-block"
              >
                {' '}
                پی ڈی ایف ڈاؤن لوڈ کریں
              </Link>
            </div>

            <div
              className="text-center mt-3 animated animatedFadeInUp fadeInUp"
              style={{ animationDelay: '0.4s' }}
            >
              <Link
                style={{ backgroundColor: '#ef7d08', color: 'white' }}
                to="/list-hadiths"
                className="btn d-block"
              >
                {' '}
                تمام احادیث{' '}
              </Link>
            </div>

            <div
              className="text-center mt-3 animated animatedFadeInUp fadeInUp"
              style={{ animationDelay: '0.4s' }}
            >
              <Link
                style={{ backgroundColor: '#ef7d08', color: 'white' }}
                to="/search"
                className="btn d-block"
              >
                <FontAwesomeIcon icon={faSearch} /> حدیث سرچ کریں
              </Link>
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: `rgba(0, 56, 102, 0.5)`,
            minHeight: '150px',
          }}
        >
          <div
            className="text-center animated animatedFadeInUp fadeInUp"
            style={{ color: 'white', animationDelay: '0.8s' }}
          >
            <h2>تحقیقات حدیث</h2>
            <p>
              موضوع (من گھڑت) لا أصل له ( بے اصل) واهي، اور مطروح احادیث کی
              تفصیلات کا سرچ انجن، کمپیوٹر اور موبائل دونوں کے لیے۔
            </p>
            <p>اور اس کے علاوہ حدیث و علوم الحدیث کی دیگر تحقیقات۔</p>
            <a href="https://forms.gle/auSAZBfkewbMNWvN9" target="_blank">
              {' '}
              اپنی قیمتی آراء سے مطلع کرنے کے لیے یہاں کلک کریں۔
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
