import React from 'react'
import { Dropdown } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'

import { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { getBooks } from './Dashboard'

const AddBookData = () => {
    // const variant = 'Secondary'

    // == firebase ==
    // get books list
    const { firebase_db } = useAuth()

    const [books, setBooks] = useState([])

    useEffect(() => {
        getBooks(firebase_db).then((value) => setBooks(value))
    }, [firebase_db])
    // ===

    const history = useHistory()

    const [selectedBook, setSelectedBook] = useState()

    const on_click_next_button = () => {
        selectedBook ? history.push(`/sync?book=${selectedBook}`) : history.push('/sync')
    }

    return (
        <div>

            <h5 className="mb-3">انتہائی احتیاط سے کتاب کا اختیار کیجئے</h5>

            <Dropdown onSelect={(ek) => {
                setSelectedBook(books[ek])
                } }>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {selectedBook || "فہرست میں سے کتاب اختیار کیجئے"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {books.map((val, i) => <Dropdown.Item key={i} eventKey={i}>{val}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>

            <div className="d-flex justify-content-start mt-2">
                <button className="btn btn-success w-100 mt-2" onClick={on_click_next_button} disabled={!selectedBook}>مندرجہ بالا کتاب میں اندراج کے لیے یہاں کلک کریں</button>
            </div>
            
        </div>
    )
}

export default AddBookData
