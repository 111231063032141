import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

const Logout = () => {

    const { logout } = useAuth()
    const history = useHistory()

    async function handleLogout () {
        // setError('')
        try {
            await logout()
            history.push('/login')
        } catch {
            // setError('Failed to log out')
            console.log('Failed to log out')
        }
    }

    useEffect(() => {
        handleLogout()
        // return () => {
        //     cleanup
        // }
    }, [])


    return (
        <div>
            Logging out...
        </div>
    )
}

export default Logout
