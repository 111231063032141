import sync_cleaner_function from "./sync_cleaner_function"
const mini_html_processor = (html_content) => {

    const parser = new DOMParser()
    const docx = parser.parseFromString(html_content, "text/html")
    const raw_text = docx.body.textContent

    const html_string = html_content.replaceAll('&quot;', "'").replaceAll('<o:p>', '').replaceAll('</o:p>', '').replaceAll('&nbsp;', ' ').replaceAll('<br>', '')
    const cleaned_html = sync_cleaner_function(html_string)

    return [cleaned_html, raw_text]
}

export default mini_html_processor