import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import DefaultContainer from './DefaultContainer'

const PrivateStyledRoute = ({ component: Component, style_component:StyleComponent, above, active_key, class_name, ...rest}) => {
    const { currentUser } = useAuth()

    const Style = StyleComponent ? StyleComponent : DefaultContainer

    return (
    
        <Route
        {...rest}
        render={(props) => currentUser ? <Style {...props} inner={Component} above={above} active_key={active_key} class_name={class_name} /> : <Redirect to='/login' />}
        >
        </Route>
    )
}

export default PrivateStyledRoute