import React from 'react'

import { Accordion } from 'react-bootstrap'

import { Card } from 'react-bootstrap'

const HadithContentAccordion = ({hadith_array, matchArray}) => {

    const hard_coded_search_array = matchArray

    const AccordionBody = ({hadith_piece}) => hadith_piece && <Accordion.Item style={{marginInlineStart: '-0.6em'}} eventKey={0}>
                                    <Accordion.Header><div className="hadith-heading" dangerouslySetInnerHTML={{__html: hadith_piece.heading}}></div></Accordion.Header>
                                    <Accordion.Body className="hadith-content" dangerouslySetInnerHTML={{__html: hadith_piece.content}}>
                                    </Accordion.Body>
                                </Accordion.Item>

    return (
        <div>
            {hadith_array && <div>
                {hadith_array.map((hadith_piece, idx) => {

                    const normal = hard_coded_search_array.includes(idx) ?
                    (<Accordion defaultActiveKey={0} flush key={idx}>
                        <AccordionBody hadith_piece={hadith_piece} />
                    </Accordion>) :
                                    (<Accordion flush key={idx}>
                                        <AccordionBody hadith_piece={hadith_piece} />
                                    </Accordion>)

                    const no_content = <div key={idx}>
                                            <Card.Body className="mt-3 hadith-heading super-heading mb-3"><div dangerouslySetInnerHTML={{__html: hadith_piece.heading}}></div></Card.Body>
                                        </div>

                    const no_heading = <div key={idx} className="hadith-content upper-text" dangerouslySetInnerHTML={{__html: hadith_piece.content}}>
                                        </div>

                    if (hadith_piece.heading && hadith_piece.content) {
                        // console.log(`norm ${idx}`)
                        return normal
                    }
                    else if (hadith_piece.heading && !hadith_piece.content) {
                        return no_content
                    }
                    else if (!hadith_piece.heading && hadith_piece.content) {
                        return no_heading
                    }
                    // this should never happen
                    else {
                        return <div key={idx}>Error 404</div>
                    }

                }
                )}
            </div>}
        </div>
    )
}

export default HadithContentAccordion
