import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import { useEffect } from 'react';

import HadithRenderer from './HadithRenderer';
import { useAuth } from '../contexts/AuthContext';
import { Modal } from 'react-bootstrap';
import { Button, Spinner } from 'react-bootstrap';
import ReactHelmet from '../seo/ReactHelmet/ReactHelmet';

const HadithDisplayPage = () => {
  const search = useLocation().search;
  const q_id = new URLSearchParams(search).get('id');

  const full_search_keyword = new URLSearchParams(search).get('search_keyword');

  const [message, setMessage] = useState('حدیث لوڈ ہو رہی ہے۔۔۔');

  const [deleteMessage, setDeleteMessage] = useState();

  const [matchArray, setMatchArray] = useState();

  const [highlightedHadith, setHighlightedHadith] = useState();

  const { currentUser } = useAuth();
  const [showPopUp, setShowPopUp] = useState(false);

  const [expandState, setExpandState] = useState('default');
  const [expandMatchArray, setExpandMatchArray] = useState([]);

  const search_hadith = async (hadith_id) => {
    // todo: add base url
    // const base_url = `http://tehqeqatehadees.com`
    const base_url = process.env.REACT_APP_BASE_URL;
    const endpoint = `${base_url}/api/v1/hadith/?id=${hadith_id}`;

    let response = await fetch(endpoint);

    if (response.ok) {
      let hadith_json = await response.json();

      if (Object.keys(hadith_json).length === 0) {
        setMessage(`Hadith with id ${q_id} doesn't exist`);
      } else {
        if (full_search_keyword) {
          create_match_array(hadith_json);
        } else {
          setMatchArray([]);
          setHighlightedHadith(hadith_json);
          const hadith_content_array = JSON.parse(hadith_json.html_content);
          setExpandMatchArray(hadith_content_array.map((k, i) => i));
        }
      }
    }
  };

  useEffect(() => {
    search_hadith(q_id);
  }, []);

  const create_match_array = (hadith) => {
    // search hadith for keyword

    // const keyword_list = full_search_keyword.split(' ')
    // -- fix 1 -- split based on comma as well
    const keyword_list = full_search_keyword.split(/(?:،| )+/g);

    const full_match_array = [];

    const hadith_content_array = JSON.parse(hadith.html_content);
    setExpandMatchArray(hadith_content_array.map((k, i) => i));
    const parser = new DOMParser();

    for (let search_keyword of keyword_list) {
      const match_array = [];

      // create match arrays
      for (let i = 0; i < hadith_content_array.length; i++) {
        let heading_html_text = hadith_content_array[i].heading;
        let heading_text = parser.parseFromString(
          heading_html_text,
          'text/html',
        ).body.textContent;

        let content_html_text = hadith_content_array[i].content;
        let content_text = parser.parseFromString(
          content_html_text,
          'text/html',
        ).body.textContent;

        // const text_to_search = new RegExp(`[\s.,]${search_keyword}[\s.,]`, "g")

        const heading_text_list = heading_text.split(/(?:،| |۔)+/g); // U+060C U+06D4
        const content_text_list = content_text.split(/(?:،| |۔)+/g);

        // if (heading_text.includes(search_keyword) || content_text.includes(search_keyword)) {
        //     match_array.push(i) // matching this keyword only
        //     // == //
        //     full_match_array.push(i) // matching any of the keywords
        // }

        if (
          heading_text_list.includes(search_keyword) ||
          content_text_list.includes(search_keyword)
        ) {
          match_array.push(i); // matching this keyword only
          // == //
          full_match_array.push(i); // matching any of the keywords
        }
      }

      // ==

      // replace all
      for (const matching_index of match_array) {
        const heading_str = hadith_content_array[matching_index].heading;
        const content_str = hadith_content_array[matching_index].content;

        // const new_heading_str = heading_str.replaceAll(search_keyword, `<span class="highlighted-text">${search_keyword}</span>`)
        // const new_content_str = content_str.replaceAll(search_keyword, `<span class="highlighted-text">${search_keyword}</span>`)

        const reg = new RegExp(
          `(?:[\\s.,>])${search_keyword}(?:[\\s.,<])`,
          'g',
        );

        const new_heading_str = heading_str.replaceAll(
          reg,
          (match) =>
            `${match[0]}<span class=highlighted-text>${match.slice(
              1,
              -1,
            )}</span>${match.slice(-1)}`,
        );
        const new_content_str = content_str.replaceAll(
          reg,
          (match) =>
            `${match[0]}<span class=highlighted-text>${match.slice(
              1,
              -1,
            )}</span>${match.slice(-1)}`,
        );

        hadith_content_array[matching_index].heading = new_heading_str;
        hadith_content_array[matching_index].content = new_content_str;

        // hadith.html_content = JSON.stringify(hadith_content_array, null, 4)
      }
    }

    hadith.html_content = JSON.stringify(hadith_content_array, null, 4);
    setMatchArray(full_match_array);

    // setMatchArray(match_array)

    // --- HIGHLIGHT MATCHING TEXT -- //

    // TODO
    // -- Also color headings above -- //

    for (let search_keyword of keyword_list) {
      const titleHtml = hadith.title;

      const reg = new RegExp(`(?:[\\s.,>])${search_keyword}(?:[\\s.,<])`, 'g');
      const new_title_str = titleHtml.replaceAll(
        reg,
        (match) =>
          `${match[0]}<span class=highlighted-text>${match.slice(
            1,
            -1,
          )}</span>${match.slice(-1)}`,
      );
      hadith.title = new_title_str;

      if (hadith.hukm) {
        const hukmHtml = hadith.hukm;
        const new_hukm_str = hukmHtml.replaceAll(
          reg,
          (match) =>
            `${match[0]}<span class=highlighted-text>${match.slice(
              1,
              -1,
            )}</span>${match.slice(-1)}`,
        );
        hadith.hukm = new_hukm_str;
      }
    }

    setHighlightedHadith(hadith);
  };

  // delete function
  const handleDelete = () => {
    setShowPopUp(false);
    // TODO: api call

    // http://0.0.0.0:8000/api/v1/hadith/?id=eN7PFnsBn0Ur-i-vwZ9C
    delete_hadith(q_id);
  };

  const delete_hadith = async (hadith_id) => {
    // const base_url = `http://0.0.0.0:8000`
    // const base_url = `http://tehqeqatehadees.com`
    const base_url = process.env.REACT_APP_BASE_URL;

    const endpoint = `${base_url}/api/v1/hadith/?id=${hadith_id}`;

    const { Aa: token } = currentUser;

    let response = await fetch(endpoint, {
      method: 'DELETE',
      headers: {
        // 'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      //   body: JSON.stringify(payload)
    });

    let result = await response.json();
    if (result?.deleted) {
      setDeleteMessage('Hadith has been deleted');
    }
  };

  // Get the title of the hadith
  const title = JSON.stringify(highlightedHadith?.title) || ' ';

  // Get the description of the hadith
  const description = JSON.stringify(highlightedHadith?.raw_content) || ' ';

  // Split the description into keywords
  const keywords = description.split(' ');

  // Create a regex pattern to match HTML tags
  const regex = /(?<=<.+.>)([^<\n]*?)(?=<.*\/.+.?>)/g;

  // Find all matches for HTML tags in the title
  const matchesTitle = [...title?.matchAll(regex)];

  // Create a string to store the title text
  let titleText = '';

  // Add all matches for HTML tags to the title text
  for (const match of matchesTitle) {
    titleText += match[1];
  }
  return (
    <div>
      <ReactHelmet
        canonical={window.location.href}
        author={` ${'تحقیقات حدیث'} | ${highlightedHadith?.book} `}
        title={` ${'تحقیقات حدیث'} | ${
          titleText.length > 50 ? titleText.slice(0, 40) + '...' : titleText
        } `}
        description={`${
          description.length > 170 ? description.slice(0, 170) : description
        }`}
        keyWords={[titleText.split(' ')]}
      />
      <div className="my-4">
        {currentUser && (
          <>
            {
              <Modal
                style={{ fontFamily: 'NotoUrdu' }}
                show={showPopUp}
                onHide={() => setShowPopUp(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Body>
                    خبردار!!! "ڈلیٹ کریں" کے بٹن پر کلک کرنے کے بعد یہ حدیث ویب
                    سائٹ سے ڈلیٹ ہوجائے گی۔
                  </Modal.Body>
                </Modal.Header>

                <Modal.Footer>
                  <Button variant="danger" onClick={handleDelete}>
                    ڈلیٹ کریں
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowPopUp(false)}
                  >
                    واپس جایئں
                  </Button>
                </Modal.Footer>
              </Modal>
            }
            <div
              className="text-center mt-3 animated animatedFadeInUp fadeInUp"
              style={{ animationDelay: '0.4s' }}
            >
              <button
                style={{ backgroundColor: 'brown', color: 'white' }}
                className="btn d-block"
                onClick={() => setShowPopUp(true)}
              >
                اس حدیث کو ویب سائٹ سے حذف کرنے کے لیے یہاں کلک کریں
              </button>
            </div>
          </>
        )}
        {highlightedHadith && (
          <div>
            {
              <Button
                disabled={expandState === 'collapse'}
                className="mx-3 my-4 btn-new"
                size="lg"
                onClick={() => setExpandState('collapse')}
              >
                طائرانہ نظر
              </Button>
            }
            {
              <Button
                disabled={expandState === 'expand'}
                className="mx-3 my-4 btn-new"
                size="lg"
                onClick={() => setExpandState('expand')}
              >
                تفصیلی نظر
              </Button>
            }
          </div>
        )}
        {/* {expandState === 'default' && (deleteMessage ? <h2 className="my-3">{deleteMessage}</h2> : highlightedHadith && matchArray ? <HadithRenderer matchArray={matchArray} jsonPOST={highlightedHadith} /> : <><h2 className="my-3">{message}</h2>{message === 'حدیث لوڈ ہو رہی ہے۔۔۔' && <Spinner animation="border" variant="warning" role="status"></Spinner>}</>)} */}
        {expandState === 'default' &&
          (deleteMessage ? (
            <h2 className="my-3">{deleteMessage}</h2>
          ) : highlightedHadith ? (
            <HadithRenderer
              matchArray={matchArray}
              jsonPOST={highlightedHadith}
            />
          ) : (
            <>
              <h2 className="my-3">{message}</h2>
              {message === 'حدیث لوڈ ہو رہی ہے۔۔۔' && (
                <Spinner
                  animation="border"
                  variant="warning"
                  role="status"
                ></Spinner>
              )}
            </>
          ))}
        {expandState === 'expand' && highlightedHadith && (
          <HadithRenderer
            matchArray={expandMatchArray}
            jsonPOST={highlightedHadith}
          />
        )}
        {expandState === 'collapse' && highlightedHadith && (
          <HadithRenderer jsonPOST={highlightedHadith} />
        )}
      </div>
    </div>
  );
};

export default HadithDisplayPage;
