import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth  } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

export default function Signup() {

    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { updatePassword } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        // if passwords do not match
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match!')
        }
        try {
            setError('')
            setLoading(true)
            await updatePassword(passwordRef.current.value)
            history.push('/')
        } catch(e) {
            console.log(e)
            setError('Failed to change password :(')
        }
        
        setLoading(false)
    }

    return (
        <>
            <Card className="mt-4" >
                <Card.Body>
                    <h2 className="text-center mb-4">Change password</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="id_password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep the same" />
                        </Form.Group>
                        <Form.Group id="id_password-confirm">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} placeholder="Leave blank to keep the same" />
                        </Form.Group>
                        <Button disabled={loading} type="submit" className="w-100 mt-3">Update</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                <Link to="/">Cancel</Link>
            </div>
        </>
    )
}
