import '../SyncStyle.css'
import SyncRTEClass from './SyncRTEClass'

import { useState, useRef } from 'react'

import htmlCleanerFunction from '../parsing/sync_cleaner_function'
import hadithJsonParserFunction from '../parsing/sync_hadith_json_parser_function'
import referencesCleanerFunction from '../parsing/sync_references_lang_cleaner_function'
import mini_html_processor from '../parsing/mini_html_processor'

// rest of the input fields
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import HadithRenderer from './HadithRenderer'

import SyncRTEMiniClass from './SyncRTEMiniClass'
import SyncRTEMiniClass2 from './SyncRTEMiniClass2'

import { useAuth } from '../contexts/AuthContext'

const SyncRTE = () => {
  
    const { currentUser } = useAuth()

    const { Aa:token, refreshToken } = currentUser

    const search = useLocation().search;
    const q_book = new URLSearchParams(search).get('book');

    const bookRef = useRef()

    const newTitleRef = useRef()
    const newHukmRef = useRef()
  
    const [message, setMessage] = useState('')
  
    const [jsonPOST, setJsonPOST] = useState()

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

    const on_click_save_button = (parsed_array, refStr, raw_content) => {

      const [titleHtml, titleRaw] = mini_html_processor(newTitleRef.current.value)
      const [hukmHtml, hukmRaw] = mini_html_processor(newHukmRef.current.value)

      const total_raw_content = `${bookRef.current.value} ${titleRaw} ${hukmRaw} ${raw_content}`

      const parsed_array_str = JSON.stringify(parsed_array, null, 4)

      const json_payload = {
        "book": bookRef.current.value,
        "type": hukmRaw !== "" ? ('فصل اول') : ('فصل ثانی'),
        "title": titleHtml,
        // -- html_content is actually serialized json for now -- TODO -- create another search page
        "html_content": parsed_array_str,
        "raw_content": total_raw_content,
      }

      if(hukmRaw !== ''){json_payload["hukm"] = hukmHtml}

      if(refStr !== ''){json_payload["references"]= refStr}

      setJsonPOST(json_payload)

      if (titleRaw === '') {
        setMessage('title can not be empty')
        setSubmitButtonDisabled(true)  
      }
      else {
        setMessage('')
        setSubmitButtonDisabled(false)
      }      
      
    }

    const on_click_confirm_button = async () => {
      setSubmitButtonDisabled(true)
      post_hadith_json(jsonPOST)
    }

    const post_hadith_json = async (payload) => {

      // const base_url = `http://0.0.0.0:8000`
      // const base_url = `http://tehqeqatehadees.com`
      const base_url = process.env.REACT_APP_BASE_URL

      const endpoint = `${base_url}/api/v1/hadith/`
  
      let response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });
  
      let result = await response.json();

      // setMessage(`حدیث ایؐ ڈی [ ${result.id} ] شامل کر دی گیْ ہے`)
      if(alert('حدیث شامل کر دی گئی ہے۔۔۔ ')) {}
      else    window.location.reload(false);
  
    }

    // ==
  
    return (
        <>
            
          {/* == INPUT PART == */}
          <div style={{ fontSize: '24px' }}>
            <div className="my-5">
              <h1>صفحہ اندراج</h1>
            </div>

            <Form.Group className="my-4" controlId="formBasicEmail">
              <Form.Label>کتاب</Form.Label>
              {q_book ? <Form.Control as="textarea" ref={bookRef} value={q_book} className='pt-4' readOnly /> : <Form.Control as="textarea" className='pt-4' ref={bookRef} />}
            </Form.Group>

            <Form.Group className="my-4" controlId="formBasicEmail">
              <Form.Label>عنوان</Form.Label>
              <div>
                  <SyncRTEMiniClass passed_ref={newTitleRef} />
              </div>
            </Form.Group>

            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>حکم</Form.Label>
              <div>
                  <SyncRTEMiniClass2 passed_ref={newHukmRef}/>
              </div>
            </Form.Group>

            <h3 className='mt-5 mb-3'>تفصیل</h3>

            <div>
                <SyncRTEClass referencesCleanerFunction={referencesCleanerFunction} cleanerFunction={htmlCleanerFunction} previewFunction={on_click_save_button} jsonParser={hadithJsonParserFunction} />
            </div>

          </div>

          {/* == RENDER PART == */}
          <div>
            {jsonPOST && <HadithRenderer jsonPOST={jsonPOST} />}
          </div>

          {jsonPOST && <div className="d-flex justify-content-end">
            <button className="btn btn-success w-25 my-3" disabled={submitButtonDisabled} onClick={on_click_confirm_button}>Confirm</button>
          </div>}

          <div className="my-3 d-flex justify-content-center">
            <h3 style={{ color: 'green' }}>{message}</h3>
          </div>
        </>
    )
}

export default SyncRTE
